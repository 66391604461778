* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Color Variables */
:root {
  --PrimaryColor: #3A75C4;
  --HoverColor: #B0D4F1;
  --AccentColor: #EB5757;
  --whiteColor: #FFFFFF;
  --blackColor: #121212;
  --textColor: #4F4F4F;
  --bgColor: #F4F8FB;
}

/* Base Typography */
h1, h2, h3, h4, h5, h6 {
  color: var(--PrimaryColor);
  font-weight: 600;
}

p {
  color: var(--textColor);
  line-height: 1.6;
}

/* Link Styling */
a {
  color: var(--PrimaryColor);
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  color: var(--HoverColor);
}

/* Button Styling */
.btn-primary {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: var(--HoverColor);
}

/* Box Shadows */
.shadow-md {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.shadow-lg {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}


/* header sliding feature */
/* Header Container */
.header-container {
  background-color: #f5f5f5; /* Light background color */
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

/* Title and Description */
.header-title-container {
  margin-bottom: 20px;
}

.header-title-container h1 {
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  margin: 0;
}

.header-title-container p {
  font-size: 1.2rem;
  color: #666;
  margin: 5px 0 0;
}

/* Sliding Box */
.header-slider-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

/* Swiper Container */
.header-swiper {
  width: 100%;
}

.header-swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #ffffff; /* White background for slides */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for slides */
}

.header-swiper-slide h3 {
  font-size: 1.8rem;
  color: #007bff; /* Primary color for headings */
  margin-bottom: 10px;
}

.header-swiper-slide p {
  font-size: 1rem;
  color: #555;
}





/* Chat Page Styling */
.chat-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 120px); /* Ensure proper height to fit viewport */
  margin: 0 auto;
  max-width: 800px; /* Limit chat width for readability */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: var(--whiteColor);
}

.chat-header {
  text-align: center;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between messages */
}

.chat-message {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  word-wrap: break-word;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
}

.chat-message.sent {
  align-self: flex-end; /* Align sent messages to the right */
  background-color: #d1f7c4; /* Light green background for sent messages */
  border-radius: 15px 15px 0 15px; /* Adjusted rounded corners */
}

.chat-message.received {
  align-self: flex-start; /* Align received messages to the left */
  background-color: #f1f1f1; /* Light gray background for received messages */
  border-radius: 15px 15px 15px 0; /* Adjusted rounded corners */
}

/* Add timestamps below messages */
.chat-message::after {
  content: attr(data-timestamp);
  display: block;
  font-size: 0.75rem;
  color: var(--textColor);
  margin-top: 0.25rem;
  text-align: right;
}

/* Chat Footer (Input Area) */
.chat-input-container {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  background-color: #f4f8fb;
  border-top: 1px solid var(--HoverColor);
}

.chat-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--HoverColor);
  border-radius: 8px;
  font-size: 1rem;
  outline: none;
}

.chat-send-button {
  padding: 0.75rem 1rem;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.chat-send-button:hover {
  background-color: var(--HoverColor);
}

/* Placeholder Styling for Empty Messages */
.chat-placeholder {
  text-align: center;
  color: var(--textColor);
  font-size: 1rem;
  opacity: 0.7;
  padding: 2rem 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat-container {
    height: calc(100vh - 100px); /* Adjust height for smaller screens */
    padding: 0.5rem;
  }

  .chat-header {
    font-size: 1.25rem;
    padding: 0.75rem;
  }

  .chat-messages {
    gap: 0.75rem;
  }

  .chat-message {
    font-size: 0.8rem;
  }

  .chat-input-container {
    flex-direction: column; /* Stack input and button vertically */
  }

  .chat-send-button {
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
}

.chat-placeholder {
  text-align: center;
  color: var(--textColor);
  font-size: 1.2rem;
  opacity: 0.7;
  padding: 2rem 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}


/* Groups Container Styling */
.groups-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: #f9f9f9; /* Light background for contrast */
}

.groups-item {
  padding: 1rem 1.5rem; /* Add some padding for better spacing */
  margin-bottom: 0.75rem;
  background-color: var(--whiteColor);
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  display: flex;
  flex-direction: column; /* Stack group name and last message vertically */
  align-items: flex-start; /* Align content to the left */
}

/* Group Name Styling */
.groups-item h4 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0; /* Remove default margin */
  color: var(--PrimaryColor);
}

/* Last Message Styling */
.groups-item p {
  font-size: 0.9rem; /* Slightly smaller font size for last message */
  color: var(--textColor);
  margin: 0.25rem 0 0; /* Add small spacing above the last message */
  overflow: hidden;
  text-overflow: ellipsis; /* Truncate long messages with ellipsis */
  white-space: nowrap; /* Prevent wrapping */
}

/* Hover Effect */
.groups-item:hover {
  background-color: var(--HoverColor);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
}

.sender-name {
  font-size: 0.85rem;
  font-weight: bold;
  color: #4F4F4F; /* Darker text for the sender's name */
  margin-bottom: 0.25rem;
}

.sender-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
nav.flex.items-center {
  background: #DAEFFF;
}
header nav.flex.items-center.justify-between .hidden button.btn {
  padding: 10px 24px;
  font-size: 19px;
  background: var(--PrimaryColor);
  font-weight: 600;
  border-radius: 5px;
  color: #daefff;
}
header nav.flex.items-center.justify-between a.text-sm {
  font-size: 18px;
  letter-spacing: 0.5px;
}
.header-container {
  background: #DAEFFF;
  padding-bottom: 40px;
}
.header-container .header-title-container h1 {
    color: #29B6F6;
}
.header-container .header-title-container p {
    color: #29597B;
    font-weight: 600;
}
.header-container .header-slider-container .swiper-pagination {
  top: unset !important;
  bottom: 0;
}
.header-container .header-slider-container .swiper.swiper-initialized {
  padding-bottom: 30px;
}
#testimonials {
  padding-top: 70px;
  padding-bottom: 70px;
}
.contactUsSec {
  background: #f9f9f9;
  padding-bottom: 70px;
}
#testimonials .swiper-slide {
  background: #f4f4f4;
  border-radius: 8px;
}
#testimonials .swiper-slide p {
    font-size: 16px;
    letter-spacing: 0.5px;
}
#testimonials .swiper-slide figcaption {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.contactUsSec .max-w-screen-xl.mx-auto.py-24 {
  padding-top: 70px;
  padding-bottom: 0;
}
.contactUsSec .grid .p-8 {
  box-shadow: 0 0 5px 5px rgb(226 226 226 / 50%);
  margin: 0 15px;
  padding: 20px;
}
.contactUsSec .grid .p-8 h6 {
  font-size: 20px;
}
.contactUsSec .grid .p-8 p {
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-top: 10px;
}
.checklist-container .w-full {
  margin-top: 0;
  background: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white img {
  width: 150px;
  height: 100px;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white .flex h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white .flex p.text-gray-500.text-sm {
  font-size: 16px !important;
  color: #555;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white .flex p span.statusBox {
  background: #ccc;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white .flex p span.statusBox.pending {
  background: #ef5b5b;
  color: #fff;
}
.checklist-container .grid.grid-cols-1 .relative.bg-white .flex p span.statusBox.completed {
  background: #86ff86;
  color: #444;
}
.taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 {
    padding: 25px;
}
.taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 h2 {
    font-size: 25px;
    margin-bottom: 20px;
}
.taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 ul li {
    margin-bottom: 15px;
}
.taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 ul li button.text-left {
    padding: 10px 10px;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
    color: #333;
}
.taskDetail  .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6 {
    padding: 25px;
}
.taskDetail .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6 .tabs img {
    margin-right: 15px;
    width: 130px;
    height: 130px;
}
.taskDetail .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6 .tabs ol {
    padding-left: 19px !important;
}
.taskDetail .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6 .tabs ul {
    padding-left: 15px !important;
    padding-top: 8px;
    padding-bottom: 8px;
}
.taskDetail .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6 .tabs ul li {padding-bottom: 10px;letter-spacing: 0.5px;}
.loader {
  position: relative;
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
.loader img {
  width: 110px;
}
.chcklistProgess .bg-blue-600.h-2.rounded-full {
  height: 24px;
  position: relative;
  display: block;
}
.chcklistProgess {
  height: 24px;
  background: #e3e3e3 !important;
  padding: 0 0 !important;
  position: relative;
  display: block;
  margin: 25px 0 45px !important;
}
.userProfileBox img.w-12.h-12.rounded-full {
  border: 1px solid #cecece;
}
.loginPop button.py-2.px-6.bg-blue-500.text-white.rounded-md.font-semibold.shadow-md.w-full {
  background: #3A75C4 !important;
  height: 49px;
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
}
.loginPop button.mt-4.text-sm.text-gray-500 {
  background: #ccc;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  color: #222;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
@media screen and (min-width:768px) {
  .checklist-container .w-full .bg-white.p-4.shadow-md.rounded-lg.text-center.mb-4 {
    width: 80%;
    margin: 0 auto;
    padding: 40px 20px;
  }
  .footerSec {
    padding-top: 50px;
  }
  .footerSec .grid.gap-16.footTop p.text-sm.text-gray-700.leading-6 {
      font-size: 16px;
      letter-spacing: 0.5px;
  }
  .footerSec .footBottom {
      padding-bottom: 0;
  }
  .footerSec .footBottom p.text-sm.text-gray-600 {
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #222;
  }
  .footerSec .footBottom .flex.items-center a.text-gray-500 {
      width: 40px;
      height: 40px;
      background: #f4f4f4;
      border-radius: 50%;
      justify-content: center;
      vertical-align: middle;
      display: inline-flex;
      line-height: 30px;
      align-items: center;
      color: #767676;
  }
  section.aboutHeader h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  section.aboutHeader .px-6.py-32 .flex.flex-col p.text-sm.md\:text-base.text-gray-700.font-medium {
      margin-top: 20px !important;
      font-size: 19px !important;
      font-weight: 500;
      text-align: center;
  }
  section.aboutHeader .px-6.py-32 .flex.flex-col button.inline-block {
      background: #3a75c4;
      border-radius: 5px;
  }
  .chcklistProgess {
    width: 60%;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .chcklistProgess {
    width: 60%;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .userProfileBox {width: 40%;margin: auto;}
  .profileUser {
      width: 50%;
      margin: auto;
      justify-content: center;
  }
}
@media screen and (max-width:767px) {
  .sideBarMobile {
    padding: 0;
  }
  .sideBarMobile .logHead {
      padding: 1rem;
      background: #DAEFFF;
  }
  .sideBarMobile .menuHead {
      padding: 0 5px;
  }
  .sideBarMobile .menuHead a.block.text-base.font-semibold {
      background: #f4f4f4;
      padding: 15px 24px;
      font-size: 19px;
      margin-bottom: 15px;
  }
  .sideBarMobile .menuHead .mt-6 {
      margin-top: 0;
  }
  .sideBarMobile .menuHead button.btn.w-full {padding: 15px 24px;font-size: 19px;background: #daefff;color: var(--PrimaryColor);font-weight: 600;width: 100%;border-radius: 5px;}
  .checklist-container .w-full {
    margin-top: 0;
    background: #f9f9f9;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .checklist-container .w-full .grid.grid-cols-1.gap-4 .relative.bg-white {
      padding: 6px;
      width: 100%;
  }
  .checklist-container .w-full .grid.grid-cols-1.gap-4 .relative.bg-white .flex.items-center img {
      width: 134px;
      height: 90px;
      object-fit: cover;
  }
  .checklist-container .w-full .grid.grid-cols-1.gap-4 .relative.bg-white .flex.items-center div {
      margin-left: 6px;
  }
  .checklist-container .grid.grid-cols-1 .relative.bg-white .flex h4 {
    font-size: 16px;
    margin-bottom: 9px;
  }
  .footerSec .footBottom {
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  .footerSec .footBottom a {
      width: 40px;
      height: 40px;
      background: #f4f4f4;
      border-radius: 50%;
      justify-content: center;
      vertical-align: middle;
      display: inline-flex;
      line-height: 30px;
      align-items: center;
      color: #767676;
  }
  .taskDetail {
    margin-top: 40px;
  }
  .taskDetail p img {
    display: block;
    width: 100%;
    margin: 10px auto;
  }
  .taskDetail ol,.taskDetail ul {
    padding-left: 15px !important;
  }
  section.aboutHeader .px-6.py-32 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  section.aboutHeader {
      min-height: auto;
  }
  .contactUsSec .max-w-screen-xl.mx-auto.py-24 {
      padding-top: 70px;
      padding-bottom: 0;
  }
  .contactUsSec .grid .p-8 {
      box-shadow: 0 0 5px 5px rgb(226 226 226 / 50%);
      margin-bottom: 30px;
      padding: 20px;
  }
  .contactUsSec .grid .p-8 h6 {
      font-size: 20px;
  }
  .contactUsSec .grid .p-8 p {
      font-size: 18px;
      letter-spacing: 0.5px;
      margin-top: 10px;
  }
  .taskDetail .w-full .bg-white.p-6.rounded-lg.shadow-lg.mb-6, .taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 {
    padding: 15px;
  }
  .taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 h2 {
    font-size: 23px;
    margin-bottom: 20px;
    text-align: center;
  }
  .taskDetail .w-full.bg-blue-50.p-4.rounded-lg.shadow-lg.mb-6 ul {
    padding: 0 !important;
  }
  .checklist-container .grid.grid-cols-1 .relative.bg-white .flex p span.statusBox {
    padding: 2px 6px;
    font-size: 13px;
    min-width: 80px;
    display: inline-block;
    text-align: center;
  }
}




